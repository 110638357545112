import { Box, Button, Center, Checkbox, CircularProgress, Divider, Flex, FormControl, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Field, Formik } from "formik";
import { motion } from "framer-motion";
import { find, isEmpty } from "lodash";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TbEditCircle, TbRefresh } from "react-icons/tb";
import { useCountdown, useLocalStorage } from 'usehooks-ts';
import { api } from "../api/api";
import { ETA, FullStop, Route, RouteDirection } from "../types";
import FavStop from "./FavStop";
import StopCard from "./StopCard";

interface Props {
  routeName: string,
  direction: RouteDirection,
  onRouteUpdated: () => void
}

export interface EtaHandle {
  getRoute: () => Route | undefined,
  refreshEta: () => void,
}

export const EtaList = forwardRef<EtaHandle, Props>(({ routeName, direction, onRouteUpdated }: Props, ref) => {
  const [route, setRoute] = useState<Route>();
  const [inboundFavouriteStops, setInboundFavouriteStops] = useLocalStorage<string[]>(`fav-stops-inbound-${routeName}`, []);
  const [outboundFavouriteStops, setOutboundFavouriteStops] = useLocalStorage<string[]>(`fav-stops-outbound-${routeName}`, []);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [routeLoading, setRouteLoading] = useState<boolean>(true);
  const [inboundStops, setInboundStops] = useState<FullStop[]>([]);
  const [outboundStops, setOutboundStops] = useState<FullStop[]>([]);
  const [inboundEtas, setInboundEtas] = useState<ETA[]>([]);
  const [outboundEtas, setOutboundEtas] = useState<ETA[]>([]);
  const [isLoadingEta, setIsLoadingEta] = useState<boolean>(false);
  const [etaRefreshTime, setEtaRefreshTime] = useState<moment.Moment>();
  const [etaRefreshCountdown, { startCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60,
      intervalMs: 1000,
    })

  useImperativeHandle(
    ref,
    () => {
      return {
        getRoute: () => route,
        refreshEta: getEta,
      }
    },
  )

  useEffect(() => {
    loadRoute();
    loadStops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onRouteUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  useEffect(() => {
    getEta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundStops, outboundStops]);

  useEffect(() => {
    if (etaRefreshCountdown <= 0) {
      getEta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etaRefreshCountdown]);

  async function loadRoute() {
    setRouteLoading(true);
    try {
      const route = await api.getRoute(routeName);
      if (isEmpty(route)) {
        throw Error("Route not found");
      }
      setRoute(route);
    } catch (error) {
      console.log(error);
    } finally {
      setRouteLoading(false);
    }
  }

  async function loadStops() {
    try {
      const stops = await Promise.all([
        api.getStops(routeName, RouteDirection.Inbound),
        api.getStops(routeName, RouteDirection.Outbound)
      ]);
      setInboundStops(stops[0]);
      setOutboundStops(stops[1]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getEta() {
    try {
      if (isLoadingEta || !outboundStops || !inboundStops) return;
      setIsLoadingEta(true);
      const etas = await Promise.all([
        Promise.all<ETA>(
          inboundStops.map((stop) => {
            return api.getEta(stop.stop, stop.route);
          })
        ),
        Promise.all<ETA>(
          outboundStops.map((stop) => {
            return api.getEta(stop.stop, stop.route);
          })
        ),
      ]);
      setInboundEtas(etas[0]);
      setOutboundEtas(etas[1]);
      setIsLoadingEta(false);
      setEtaRefreshTime(moment());
      resetCountdown();
      startCountdown();
    } catch (error) {
      console.log(error);
    }
  }

  function getCurrentStops() {
    return direction === RouteDirection.Outbound ? outboundStops : inboundStops;
  }

  function getCurrentEtas() {
    return direction === RouteDirection.Outbound ? outboundEtas : inboundEtas;
  }

  function getCurrentFavStops() {
    return direction === RouteDirection.Outbound ? outboundFavouriteStops : inboundFavouriteStops;
  }

  function isRouteEmpty(): boolean {
    return !routeLoading && isEmpty(route);
  }

  const stops = getCurrentStops();
  const etas = getCurrentEtas();
  const favouriteStops = getCurrentFavStops();

  return (
    <Box>
      <motion.div
        style={{
          height: 0,
          overflow: 'hidden',
        }}
        animate={{
          height: (etas && etas.length && etaRefreshTime) ? "auto" : 0,
        }}
      >
        <Box bgColor='green.100' textColor='green.600'>
          <Flex py='2' align='center' justify='center' gap='2' px='3'>
            <Box flex='1' />
            <Text>最後更新時間: {etaRefreshTime ? etaRefreshTime.format('HH:mm') : ''}</Text>
            <Box flex='1' textAlign='end'>
              <IconButton
                colorScheme='green'
                aria-label='Refresh ETA'
                size='sm'
                icon={<TbRefresh />}
                isLoading={isLoadingEta}
                onClick={getEta}
              />
            </Box>
          </Flex>
          <Progress
            colorScheme='green'
            hasStripe
            size='xs'
            value={etaRefreshCountdown}
            max={60}
            sx={{
              '& > div:first-of-type': {
                transition: 'width 1s',
              },
            }}
          />
        </Box >
      </motion.div>
      {stops && stops.length && etaRefreshTime ?
        <Box>
          <Box p='2' position='relative'>

            {
              !favouriteStops.length ?
                <Box textAlign='center' p='12'>
                  <Text>並未設置最愛車站</Text>
                </Box> :
                <Flex justify='center' gap='4' pt='2' pb='4'>
                  {
                    favouriteStops.map((stopId) => {
                      const stop = stops.find((stop) => stop.stop === stopId);
                      const eta = etas.find((eta) => eta.stop === stopId);
                      if (!stop) return undefined
                      return (
                        <FavStop
                          key={stop.stop}
                          now={etaRefreshTime}
                          stop={stop}
                          eta={eta}
                          direction={direction}
                        />
                      )
                    })
                  }
                </Flex>
            }
            <Box textAlign='end' position='absolute' bottom='3' right='3'>
              <IconButton
                aria-label='Edit favourite stations'
                size='sm'
                icon={<TbEditCircle />}
                onClick={onOpen}
              />
            </Box>
          </Box>
          <Divider />
          {
            stops.map((stop) =>
              <StopCard
                key={stop.stop}
                now={etaRefreshTime}
                direction={direction}
                stop={stop}
                eta={find(etas, (eta) => eta.stop === stop.stop)}
              />
            )
          }
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>設置最愛車站</ModalHeader>
              <ModalCloseButton />
              <Formik
                initialValues={{
                  checked: favouriteStops,
                }}
                onSubmit={(values) => {
                  if (direction === RouteDirection.Outbound) {
                    setOutboundFavouriteStops(values.checked);
                  } else {
                    setInboundFavouriteStops(values.checked);
                  }
                }}
              >
                {({ values, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <ModalBody>
                      <Box h='50vh' overflowY='scroll'>
                        <VStack spacing={4} align="flex-start">
                          {
                            stops.map((stop) =>
                              <FormControl key={stop.stop} px='2'>
                                <Field
                                  as={Checkbox}
                                  name="checked"
                                  value={stop.stop}
                                  defaultChecked={values.checked.includes(stop.stop)}
                                >
                                  {stop.name_tc}
                                </Field>
                              </FormControl>
                            )
                          }
                        </VStack>
                      </Box>
                    </ModalBody>
                    <ModalFooter>
                      <Button variant='ghost' mr={3} onClick={onClose}>取消</Button>
                      <Button bgColor='cyan.800' color='white' onClick={() => {
                        handleSubmit()
                        onClose()
                      }}>
                        儲存
                      </Button>
                    </ModalFooter>
                  </form>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </Box> :
        (
          <Center h='80vh'>
            {
              isRouteEmpty() ?
                <Text>找不到路線</Text> :
                <CircularProgress isIndeterminate color='cyan.800' />
            }
          </Center>
        )
      }

    </Box>
  )
});
