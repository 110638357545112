import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { ETA, ETAEntry, RouteDirection, Stop } from '../types';

interface Props {
  now: moment.Moment,
  direction: RouteDirection
  stop: Stop,
  eta: ETA | undefined,
}

function getEtaDirecionString(direction: RouteDirection): string {
  if (direction === RouteDirection.Outbound) {
    return "O";
  } else {
    return "I";
  }
}

function getEtaText(now: moment.Moment, etaEntry: ETAEntry): string {
  return moment(etaEntry.eta).diff(now, 'minutes') + ' 分鐘';
}

export default function StopCard({ now, direction, stop, eta }: Props) {
  const directionString = getEtaDirecionString(direction);

  return (
    <Box textAlign='center' fontSize='xl'>
      <Box py='2' px='4'>
        <Flex gap='2' align='center'>
          <Box flex='6' py='2' textAlign='start'>
            {stop.name_tc}
          </Box>
          <VStack flex='4' spacing='0' textAlign='end' align='end'>
            {eta &&
              eta.etas
                .filter((etaEntry) => etaEntry.dir === directionString)
                .map((etaEntry, i) =>
                  <Box key={`${etaEntry.stop}-${moment(etaEntry.eta).valueOf()}`}>
                    <Text fontSize={i === 0 ? 'lg' : 'sm'} textColor={i === 0 ? 'black' : 'blackAlpha.500'}>
                      {getEtaText(now, etaEntry)}
                    </Text>
                  </Box>
                )
            }
          </VStack>
        </Flex>
      </Box>
      <Divider />
    </Box>
  )
}