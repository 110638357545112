export interface Route {
  route: string
  orig_tc: string
  dest_tc: string
  data_timestamp: Date
}

export interface RouteStop {
  route: string
  dir: string // "I": Inbound, "O": Outbound
  seq: number
  stop: string // Stop ID
  data_timestamp: Date
}

export interface Stop {
  stop: string // Stop ID
  name_tc: string
  lat: number
  long: number
  data_timestamp: Date
}

export interface ETAEntry {
  route: string
  dir: string // "I": Inbound, "O": Outbound
  seq: number
  stop: string // Stop ID
  dest_tc: string
  etq_seq: number
  eta: Date
  rmk_tc: string
  data_timestamp: Date
}

export interface ETA {
  route: string,
  stop: string,
  etas: ETAEntry[],
}

export type FullStop = Stop & RouteStop

export enum RouteDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}