import axios from 'axios';
import { find, sortBy } from 'lodash';
import { ETA, ETAEntry, FullStop, Route, RouteStop, Stop } from "../types";

axios.defaults.baseURL = "https://rt.data.gov.hk/v1.1/transport/citybus-nwfb/";

class API {
  public async getStops(route: string, direction: string = "inbound", company: string = "NWFB"): Promise<FullStop[]> {
    if (route.startsWith('95') || route.startsWith('96')) company = 'CTB'
    const response = await axios.get(`/route-stop/${company}/${route}/${direction}`);
    const routeStops: RouteStop[] = response.data.data;

    const stopResponses = await Promise.all(
      routeStops.map(
        async (routeStop: RouteStop) => axios.get(`/stop/${routeStop.stop}`)
      )
    );
    const stops: FullStop[] = stopResponses.map(
      (stopResponse) => {
        const stop = stopResponse.data.data as Stop;
        const routeStop = find(routeStops, (routeStop) => routeStop.stop === stop.stop);
        if (!routeStop) {
          throw new Error(`Could not find route stop for stop ${stop.stop}`);
        }
        return { ...stop, ...routeStop };
      }
    );
    const sortedStops = sortBy(stops, (stop) => stop.seq);
    return sortedStops;
  }

  public async getRoute(route: string, company: string = "NWFB"): Promise<Route> {
    if (route.startsWith('95') || route.startsWith('96')) company = 'CTB'
    const response = await axios.get(`/route/${company}/${route}`);
    return response.data.data;
  }

  public async getEta(stop: string, route: string, company: string = "NWFB"): Promise<ETA> {
    if (route.startsWith('95') || route.startsWith('96')) company = 'CTB'
    const response = await axios.get(`/eta/${company}/${stop}/${route}`);
    const etaEntries: ETAEntry[] = response.data.data;
    const filteredEtaEntries = etaEntries.filter((etaEntry) => etaEntry.eta !== undefined);
    const sortedEtaEntries = sortBy(filteredEtaEntries, (etaEntry) => etaEntry.etq_seq);
    return {
      route: route,
      stop: stop,
      etas: sortedEtaEntries,
    }
  }
}

export const api = new API();
