import { Box, Button, Flex, FormControl, Heading, IconButton, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { Field, FieldProps, Formik } from 'formik';
import { useEffect, useReducer, useRef, useState } from 'react';
import { TbArrowsDoubleNeSw, TbMinus, TbPlus } from 'react-icons/tb';
import { useLocalStorage } from 'usehooks-ts';
import { RouteDirection } from '../types';
import { EtaHandle, EtaList } from './EtaList';

export default function MainPage() {
  const etaListRef = useRef<EtaHandle[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [routes, setRoutes] = useLocalStorage<string[]>('route-list', ['682', '682B']);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [direction, setDirection] = useState<RouteDirection>(RouteDirection.Outbound);
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    etaListRef.current = etaListRef.current.slice(0, routes.length);
  }, [routes]);

  function toggleDirection() {
    setDirection(direction === RouteDirection.Outbound ? RouteDirection.Inbound : RouteDirection.Outbound);
  }

  function getRouteName(): string {
    return routes[index];
  }

  function getRouteDisplayName(): string {
    if (!etaListRef.current.length) return '';
    const route = etaListRef.current[index].getRoute();
    if (!route) return '';
    if (direction === RouteDirection.Outbound) {
      return `${route.orig_tc} → ${route.dest_tc}`;
    } else {
      return `${route.dest_tc} → ${route.orig_tc}`;
    }
  }

  function onRouteLoaded() {
    forceUpdate();
  }

  function onTabIndexChange(i: number) {
    setIndex(i);
    etaListRef.current[i]?.refreshEta();
  }

  return (
    <Box>
      <Box bgColor='cyan.800'>
        <Flex align='center' p='3' gap='3'>
          <IconButton aria-label='Add bus' icon={<TbPlus />} onClick={onOpen} />
          <Spacer />
          <Box textAlign='center'>
            <Text color='whiteAlpha.600' fontSize='sm' fontWeight='medium' lineHeight='1'>{getRouteName()}</Text>
            <Box h='1' />
            <Heading color='white' size='sm' noOfLines={1}>{getRouteDisplayName()}</Heading>
          </Box>
          <Spacer />
          <IconButton aria-label='Switch bus direction' icon={<TbArrowsDoubleNeSw />} onClick={toggleDirection} />
        </Flex>
      </Box>
      <Tabs isFitted onChange={onTabIndexChange}>
        <TabList>
          {
            routes.map((route) => (
              <Tab key={route}>{route}</Tab>
            ))
          }
        </TabList>
        <TabPanels>
          {
            routes.map((route, i) => (
              <TabPanel key={route} p='0'>
                <EtaList
                  routeName={route}
                  direction={direction}
                  onRouteUpdated={onRouteLoaded}
                  ref={(el: EtaHandle) => etaListRef.current[i] = el}
                />
              </TabPanel>
            ))
          }
        </TabPanels>
      </Tabs>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent><ModalHeader>設置路線</ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              routes: routes,
            }}
            onSubmit={(values) => {
              setRoutes(values.routes);
            }}
          >
            {({ values, errors, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <VStack spacing='2' align='start'>
                    {
                      values.routes.map((route, i) =>
                        <Field key={i} name={`routes[${i}]`}
                          variant="filled">
                          {({ field }: FieldProps<string, any>) => (
                            <FormControl isInvalid={!field.value}>
                              <InputGroup>
                                <Input {...field} placeholder="路線名稱" />
                                <InputRightElement>
                                  <IconButton aria-label='Minus bus' colorScheme='red' icon={<TbMinus />} onClick={() => {
                                    const newRoutes = Array.from(values.routes);
                                    newRoutes.splice(i, 1);
                                    setFieldValue('routes', newRoutes);
                                  }} />
                                </InputRightElement>
                              </InputGroup>
                            </FormControl>
                          )}
                        </Field>
                      )
                    }
                    <IconButton aria-label='Add bus' icon={<TbPlus />} onClick={() => {
                      setFieldValue('routes', [...values.routes, '']);
                    }} />
                  </VStack>

                </ModalBody>
                <ModalFooter>
                  <Button variant='ghost' mr={3} onClick={onClose}>取消</Button>
                  <Button bgColor='cyan.800' color='white' onClick={() => {
                    if (!values.routes.every((route) => route.length > 0)) {
                      return;
                    }
                    handleSubmit();
                    onClose();
                  }}>
                    儲存
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  )
}
