import { AspectRatio, Box, Spacer, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { ETA, ETAEntry, FullStop, RouteDirection } from '../types';

interface Props {
  now: moment.Moment,
  direction: RouteDirection
  stop: FullStop,
  eta: ETA | undefined,
}

function getEtaDirecionString(direction: RouteDirection): string {
  if (direction === RouteDirection.Outbound) {
    return "O";
  } else {
    return "I";
  }
}

function getEtaMinute(now: moment.Moment, etaEntry: ETAEntry): string {
  return moment(etaEntry.eta).diff(now, 'minutes').toString();
}

function getEtaText(now: moment.Moment, etaEntry: ETAEntry): string {
  return moment(etaEntry.eta).diff(now, 'minutes') + ' 分鐘';
}

function FavStop({ now, direction, stop, eta }: Props) {
  const directionString = getEtaDirecionString(direction);
  const firstEta = eta?.etas[0];
  return (
    <VStack flex='1' textAlign='center' px='4' spacing='1'>
      <Text fontSize='sm'>{stop?.name_tc}</Text>
      <Spacer />
      <AspectRatio w='80%' ratio={1}>
        <Box borderRadius='50%' bgColor='green.200' color='black'>
          <Box>
            <Text fontSize='2xl' fontWeight='bold' lineHeight='1.2'>
              {firstEta ? getEtaMinute(now, firstEta) : '--'}
            </Text>
            {firstEta && <Text>分鐘</Text>}
          </Box>
        </Box>
      </AspectRatio>
      {
        eta && eta.etas
          .slice(1)
          .filter((etaEntry) => etaEntry.dir === directionString)
          .map((etaEntry) =>
            <Box key={`${etaEntry.stop}-${moment(etaEntry.eta).valueOf()}`}>
              <Text fontSize='sm' fontWeight='medium'>
                {getEtaText(now, etaEntry)}
              </Text>
            </Box>
          )
      }
    </VStack >
  )
}

export default FavStop